.wrapper {
  background-color: var(--secondary-brand-color);
  padding: 4px 10px;
}

.inner {
  max-width: 375px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoLink {
  width: 32px;
  height: 32px;
  z-index: 1202;
}

.logoIcon {
  width: 32px;
  height: 32px;
}

.navigate {
  display: flex;
  transition: opacity var(--transition), visibility var(--transition);
}

.navigateList {
  display: flex;
}

.navLink {
  outline: none;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  line-height: 1.4;
  transition: color var(--transition);
}

.loginBtn {
  font-family: var(--primary-font);
  background-color: transparent;
  outline: none;
  height: 38px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--primary-white-color);
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition),
    color var(--transition);
}

.icon {
  fill: currentColor;
}

.burgerWrapper {
  width: 52px;
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 10px;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 1202;
}

.burger {
  border-radius: 50%;
  width: 32px;
  height: 2px;
  background-color: var(--white-color);
  border-radius: 8px;
  transition: opacity var(--transition), transform var(--transition);
}

@media screen and (max-width: 374px) {
  .navigateInner {
    width: 100%;
  }

  .navItem {
    width: 100%;
  }

  .loginBtn {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .navigate {
    position: absolute;
    padding: 16px;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    background-color: var(--secondary-brand-color);
    z-index: 1201;
  }

  .navigateInner {
    padding-top: 140px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    margin: 0 auto;
    min-height: 100%;
    width: 375px;
    transition: opacity 500ms linear, visibility 500ms linear;
  }

  .navigateHorizontal {
    padding-top: 20px;
    width: 100%;
  }

  .navigateHorizontal .navLink,
  .navigateHorizontal .loginBtn {
    font-size: 22px;
    text-align: center;
  }

  .navigateHorizontal .loginBtn {
    margin-bottom: 16px;
  }

  .navigateHorizontal .navigateList {
    margin: 0 auto 32px auto;
  }

  .navigateHorizontal .socialList,
  .navigateHorizontal .copyright {
    margin: 0 auto;
  }

  .navigateList {
    margin-left: auto;
    margin-bottom: 80px;
    gap: 32px;
  }

  .navItem {
    text-align: end;
  }

  .navLink {
    font-size: 32px;
    justify-content: flex-end;
    font-family: var(--secondary-font);
    transform: translateX(0);
    color: var(--primary-white-color);
    transition: color var(--transition), transform var(--transition);
  }

  .navLink:active {
    color: var(--hover-button);
    transition: color var(--transition), transform var(--transition);
    transform: translateX(-14px);
  }

  .socialList {
    display: flex;
    gap: 40px;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .copyright {
    color: var(--primary-gray-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    text-align: right;
  }

  .copyrightlink {
    outline: none;
    display: inline-block;
    margin-left: 4px;
    color: var(--primary-gray-color);
    text-decoration: underline;
  }

  .loginBtn {
    height: 45px;
    text-align: right;
    border: none;
    font-size: 32px;
    font-family: var(--secondary-font);
    margin-bottom: 32px;
    transition: color var(--transition), transform var(--transition);
  }

  .loginBtn:active {
    color: var(--hover-button);
    transition: color var(--transition), transform var(--transition);
    transform: translateX(-14px);
  }
}

/* TABLET */

@media screen and (min-width: 768px) {
  .wrapper {
    padding: 10px 0;
  }

  .inner {
    max-width: 712px;
    margin-left: 44px;
    margin: 0 auto;
  }

  .logoLink {
    width: 60px;
    height: 60px;
  }

  .logoIcon {
    width: 60px;
    height: 60px;
  }

  .navigateInner {
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .navigateList {
    gap: 40px;
  }

  .navLink {
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .navLink:hover,
  .navLink:focus {
    color: var(--hover-button);
  }

  .burgerWrapper {
    display: none;
  }

  .navItem {
    height: 38px;
  }

  .copyright,
  .socialList {
    display: none;
  }

  .loginBtn {
    font-size: 16px;
    width: 146px;
    border-radius: 8px;
    border: 1px solid var(--primary-white-color);
  }

  .loginBtn:hover,
  .loginBtn:focus {
    border: 2px solid var(--hover-button);
    background: var(--hover-button);
    color: var(--primary-brand-color);
  }
}

/* DESKTOP */

@media screen and (min-width: 1440px) {
  .inner {
    max-width: 1352px;
  }

  .navItem {
    height: 60px;
  }
}