.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.wrapServises {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;
  width: 100%;
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: var(--primary-brand-color);
}

.text::after,
.text::before {
  display: block;
  content: "";
  width: 35%;
  height: 1px;
  background: var(--primary-gray-color);
}

.google,
.facebook {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 51px;
  width: 100%;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 500;
}

.google {
  border: 1px solid var(--primary-gray-color);
  background-color: transparent;
  color: var(--primary-brand-color);
}

.google svg {
  height: 22px;
  width: 22px;
}

.facebook {
  background-color: #39579A;
  border: none;
  color: var(--white-color);
}

.facebook svg {
  width: 14px;
  height: 24px;
}

.serviceTitle {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: 0.02em;
}