.saving {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
  color: var(--white-color);
  text-align: center;
}
