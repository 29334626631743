.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--overlay-color);
  z-index: 1250;
  overflow: auto;
}

.modal {
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 100vh;
  width: 500px;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 530px) {
  .modal {
    margin: 0;
    width: 100vw;
    height: 100vh;
  }
}
