.navigateBtn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  line-height: 1.4;
  font-size: 16px;
}

.checkboxLabel {
  color: currentColor;
  font-weight: 350;
  line-height: 1.12;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 1.4;
}

.checkboxWrapper:first-child {
  margin-bottom: 16px;
}

.customCheckboxGreen,
.customCheckboxWhite {
  cursor: pointer;
}

.customCheckboxGreen {
  stroke: var(--accepted-color);
}

.customCheckboxWhite {
  stroke: var(--white-color);
}

.customErrorCheckbox {
  stroke: var(--error-color);
}

.customEmptyCheckbox {
  stroke: currentColor;
}

.specialNavigateLink {
  color: var(--link-color);
  font-family: var(--primary-font);
  padding: 0 4px;
  transition: color var(--transition);
  text-decoration: underline;
}

.specialNavigateLink:hover,
.specialNavigateLink :focus {
  color: var(--hover-button);
}

@media screen and (max-width: 450px) {
  .checkboxWrapper {
    font-size: 14px;
  }

  .navigateBtn {
    font-size: 14px;
  }
}
