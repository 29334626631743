.wrapper {
  margin: 0 auto;
  padding-top: 36px;
  padding-bottom: 40px;
  background-position: center;
  background-repeat: no-repeat;
}

.mobileTitleWrap {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 500;
}

.subtitle {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 30px;
}

.btnWrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .wrapper {
    max-width: 375px;
    background: linear-gradient(rgba(3, 5, 30, 0.85), rgba(3, 5, 30, 0.85)),
      url("../../../assets/hero-mobile.webp");
  }

  .title {
    display: none;
  }

  .mobileTitle {
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  .mobileSubtitle {
    line-height: 1.4;
    font-size: 18px;
  }

  .subtitle {
    text-align: center;
    padding: 0 16px;
  }

  .btnWrapper {
    padding: 0 16px;
  }
}

@media screen and (min-width: 768px) {
  .wrapper {
    width: 768px;
    margin: 0 auto;
    padding-top: 145px;
    padding-bottom: 124px;
    padding-left: 28px;
    background-image: url("../../../assets/hero-tablet.webp");
    background-position: right bottom;
  }

  .title {
    width: 380px;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .mobileTitleWrap {
    display: none;
  }

  .subtitle {
    width: 355px;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .btnWrapper {
    width: 280px;
  }
}

@media screen and (min-width: 1440px) {
  .wrapper {
    width: 1352px;
    padding-top: 257px;
    padding-bottom: 102px;
    padding-left: 0;
    background-image: url("../../../assets/hero.webp");
  }

  .title {
    width: 647px;
    font-size: 72px;
    margin-bottom: 40px;
  }

  .subtitle {
    width: 413px;
    font-size: 18px;
    margin-bottom: 40px;
  }
}