.modal {
  width: 500px;
  padding: 40px;
  border-radius: 40px;
  background: var(--white-color);
  text-align: center;
  color: var(--primary-brand-color);
}

.modal button {
  right: 20px !important;
}

.title {
  font-size: 36px;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 12px;
}

.text {
  font-size: 14px;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .modal {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
