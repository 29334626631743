.wrapper {
  padding-top: 40px;
  padding-bottom: 20px;
  background-color: var(--secondary-brand-color);
}

.inner {
  margin: 0 auto;
}

.logoLink {
  width: 32px;
  height: 32px;
}

.logoIcon {
  width: 32px;
  height: 32px;
}

.addressList {
  text-align: center;
  color: var(--footer-color);
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-style: normal;
}

.link {
  outline: none;
  display: inline-block;
  color: var(--secondary-gray-color);
  transition: color var(--transition);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
}

.socialList {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.socialList li {
  width: 24px;
  height: 24px;
}

.icon,
.instagramIcon {
  display: inline-block;
  outline: none;
  cursor: pointer;
}

.icon {
  fill: var(--secondary-gray-color);
  transition: fill var(--transition);
}

.pages {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-direction: column;
}

.copyright {
  width: 90vw;
  margin: 0 auto;
  color: var(--primary-gray-color);
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
}

.copyrightlink {
  font-size: 10px;
  outline: none;
  background: none;
  border: none;
  display: inline-block;
  margin-left: 4px;
  color: var(--primary-gray-color);
  text-decoration: underline;
  transition: color var(--transition);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .profileWrapper {
    margin-bottom: 40px;
  }

  .inner {
    text-align: center;
  }

  .logoLink {
    display: inline-block;
    margin-bottom: 16px;
  }

  .addressList,
  .pages {
    margin-bottom: 16px;
  }

  .addressList li,
  .pages li {
    height: 22px;
  }

  .socialList {
    margin-bottom: 16px;
  }
}

@media screen and (min-width:768px) and (max-width: 1439px) {
  .link {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding-top: 40px;
    padding-bottom: 7px;
  }

  .inner {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }

  .logoLink {
    width: 60px;
    height: 60px;
  }

  .link:hover,
  .link:focus,
  .copyrightlink:hover,
  .copyrightlink:focus {
    color: var(--hover-button);
  }

  .logoIcon {
    width: 60px;
    height: 60px;
  }

  .addressList {
    text-align: left;
  }

  .pages {
    flex-direction: column;
    align-items: start;
  }

  .socialList {
    flex-direction: column;
  }

  .icon:hover,
  .icon:focus {
    fill: var(--hover-button);
  }

  .copyright {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1440px) {

  .copyright,
  .copyrightlink {
    font-size: 12px;
  }
}