@font-face {
  font-display: swap;
  font-family: "e-Ukraine Head";
  src: url("./e-ukrainehead-light_w.woff2") format("woff2"),
    url("./e-ukrainehead-light_w.woff") format("woff"),
    url("./e-ukrainehead-light_w.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "e-Ukraine Head";
  src: url("./e-ukrainehead-regular_w.woff2") format("woff2"),
    url("./e-ukrainehead-regular_w.woff") format("woff"),
    url("./e-ukrainehead-regular_w.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "e-Ukraine Head";
  src: url("./e-ukrainehead-medium_w.woff2") format("woff2"),
    url("./e-ukrainehead-medium_w.woff") format("woff"),
    url("./e-ukrainehead-medium_w.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "e-Ukraine Head";
  src: url("./e-ukrainehead-bold_w.woff2") format("woff2"),
    url("./e-ukrainehead-bold_w.woff") format("woff"),
    url("./e-ukrainehead-bold_w.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
