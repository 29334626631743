.closeIcon {
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: color var(--transition);
  z-index: 2;
  transition: 0.3s;
}

.closeIconR40 {
  right: 40px;
}

.closeIconWhite {
  fill: var(--white-color);
}

@media screen and (min-width: 768px) {
  .closeIcon:hover,
  .closeIcon:focus {
    fill: var(--hover-button);
  }
}
