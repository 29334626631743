.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  transition: 0.5s;
}

.overlayHide {
  visibility: hidden;
  transform: translateY(-100%);
}

.mainWrap {
  position: fixed;
  bottom: 10px;
  right: 50%;
  transform: translateX(50%);
  border-radius: 10px;
  overflow: hidden;
  height: 90%;
  max-height: 871px;
  display: flex;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  line-height: 1.4;
  visibility: inherit;
  opacity: 1;
  transition: 0.5s;
  z-index: 10000000;
}

.mainWrapHide {
  transform: translate(50%, 110%);
  visibility: hidden;
}

.iconsWrap {
  width: 120px;
  padding-top: 100px;
  background: linear-gradient(
    to left,
    rgba(221, 221, 221, 0.3),
    rgba(221, 221, 221, 0.15)
  );
}

.iconsList {
  height: 306px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: relative;
}

.icon:first-child {
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.meetingWrap {
  width: 541px;

  padding-top: 30px;
  font-family: "Baloo 2", sans-serif;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to left,
    rgba(52, 52, 52, 0.4),
    rgba(35, 35, 35, 0.24)
  );
}

.textWrap {
  padding-left: 43px;
  flex: 1 1 auto;
}

.textWrap span {
  display: block;
}

.meeting {
  font-size: 16px;
  font-weight: 600;
  color: #afc6ff;
  margin-bottom: 40px;
}

.requiem {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 11px;
}

.year {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 27px;
}

.project {
  font-size: 14px;
  font-weight: 400;
}

.teamWrap {
  overflow: auto;
  padding: 30px 0;
  width: 612px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 126px);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  column-gap: 160px;
  background: linear-gradient(
    to left,
    rgba(221, 221, 221, 0.3),
    rgba(245, 243, 243, 0.15)
  );
}

.teamateRoleWrap {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 1440px) {
  .mainWrap {
    width: 100vw;
    height: 100vh;
    bottom: 0;
    border-radius: 0;
  }

  .iconsWrap {
    width: 10%;
  }

  .meetingWrap {
    width: 40%;
    padding-top: 15px;
  }

  .textWrap {
    padding-left: 30px;
  }

  .meeting,
  .requiem,
  .year {
    margin-bottom: 10px;
  }

  .teamWrap {
    row-gap: 20px;
    column-gap: 30px;
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .iconsWrap,
  .teamateRoleWrap {
    display: none;
  }

  .mainWrap {
    flex-direction: column;
    background: linear-gradient(
      to left,
      rgba(52, 52, 52, 0.4),
      rgba(35, 35, 35, 0.24)
    );
  }

  .meetingWrap,
  .teamWrap {
    width: 94%;
    margin: 0 auto;
  }

  .meetingWrap {
    padding-top: 60px;
    padding-bottom: 23px;
    background: none;
  }

  .textWrap {
    padding-left: 0;
  }

  .teamWrap {
    column-gap: 20px;
  }

  .closeTeamPage {
    top: 57px;
  }
}
