.content {
  padding: 40px 0;
}

.title {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
}

.policyList {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-left: 20px;
}

.policyItem {
  font-size: 18px;
  font-weight: 500;
}

.policyItem p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}

.subtitle {
  font-weight: 500;
  line-height: 1.4;
  font-size: 18px;
}

.subtitle {
  margin-bottom: 20px;
}

.policySubList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}

.policySubList li {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.policySubList {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .content {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .title {
    font-size: 36px;
  }

  .policyList {
    padding-left: 40px;
  }

  .policyItem {
    font-size: 36px;
    padding-left: 0;
  }

  .subtitle {
    font-size: 36px;
  }

  .policyItemModal,
  .subtitleModal {
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .title {
    margin-bottom: 40px;
  }
}
