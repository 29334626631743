@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("./fonts/fonts.css");

:root {
  --primary-brand-color: #03051e;
  --secondary-brand-color: #0d0f28;
  --description-text-color: #3d3e3f;
  --primary-gray-color: #93999b;
  --secondary-gray-color: #d9d9d9;
  --primary-white-color: #f9f9f9;
  --white-color: #ffffff;
  --accent-color: #daac01;
  --hover-button: #eec015;
  --error-color: #86181e;
  --error-dark: #ca0000;
  --block-color: #dc3545;
  --accepted-color: #009951;
  --link-color: #5b4bad;
  --overlay-color: rgba(0, 0, 0, 0.7);
  --transition: 250ms linear;
  --react-international-phone-country-selector-arrow-size: transparent;
  --primary-font: "Inter", sans-serif;
  --secondary-font: "e-Ukraine Head", sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  color: var(--primary-white-color);
  background-color: var(--primary-brand-color);
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  font-family: var(--primary-font);
}

button,
div,
main,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--secondary-font);
}

h2 {
  font-size: 26px;
  line-height: 1.17;
  font-weight: 500;
}

h3 {
  font-size: 22px;
  line-height: 1.18;
  font-weight: 600;
}

img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--primary-brand-color);
  -webkit-box-shadow: 0 0 0px 1000px var(--primary-white-color) inset;
  transition: background-color var(--transition);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: var(--primary-brand-color);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-gray-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--hover-button);
}

@font-face {
  font-family: "pass";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/dot.woff") format("woff");
}

input[type="password"]:not(:placeholder-shown) {
  padding-top: 16px;
  font-size: 30px;
  font-family: "pass", "Roboto", Helvetica, Arial, sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -appearance: textfield;
}

.lock {
  overflow: hidden;
}

.general {
  color: var(--primary-white-color);
}

.profile {
  color: var(--secondary-bg-color);
  transition: color var(--transition), background-color var(--transition);
}

.active {
  color: var(--accent-color);
}

.profile.active {
  background-color: rgba(217, 217, 217, 0.08);
  color: var(--primary-white-color);
  position: relative;
}

.profile.active::before {
  content: "";
  top: 0;
  left: 0;
  width: 3px;
  height: 54px;
  background-color: var(--secondary-gray-color);
}

.profile.unactive {
  background-color: transparent;
}

.profile.unactive::before {
  background-color: transparent;
}

.container {
  max-width: 100%;
  padding: 0 16px;
}

.userContainer {
  width: 880px;
  margin: 0 auto;
}

#modal-root {
  position: relative;
}

@media screen and (max-width: 767px) {
  .profile.active {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
  }

  .profile.active::before {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }
}

@media screen and (min-width: 375px) {
  .container {
    max-width: 375px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 712px;
    padding: 0;
    margin: 0 auto;
  }

  h2 {
    font-size: 36px;
    line-height: 1.125;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: 1352px;
  }
}

.react-international-phone-country-selector-button__dropdown-arrow::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-bottom: 2px solid var(--primary-gray-color);
  border-right: 2px solid var(--primary-gray-color);
  margin-bottom: 4px;
  transform: rotate(45deg);
}
