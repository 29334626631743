.wrap {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: var(--accepted-color);
}

.errorMessage {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: var(--error-color);
}
