.formInput {
  border: 1px solid var(--primary-gray-color);
  border-radius: 12px;
  padding: 8px 8px 16px;
  height: 48px;
  width: 100%;
  outline: none;
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 1px;
  color: var(--primary-brand-color);
  background-color: var(--primary-white-color);
  transition: border-color var(--transition), color var(--transition);
}

.profileInput {
  background-color: transparent;
  color: var(--primary-white-color);
}

input[type="password"] {
  padding-right: 44px;
}

.formInput::placeholder {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: var(--primary-gray-color);
  letter-spacing: normal;
  padding-top: 16px;
}

.formLabel {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: var(--primary-brand-color);
  height: 76px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.icon {
  transition: color var(--transition);
  fill: currentColor;
  cursor: pointer;
}

.errorMessage {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  position: absolute;
  top: 0;
  right: 0;
  color: var(--error-color);
}

.backIcon {
  position: absolute;
  top: 40px;
  right: 12px;
}

.errorsList {
  list-style: inside;
}

.errorItem {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: var(--accepted-color);
}

.errorItem::marker {
  color: var(--accepted-color);
  font-size: 8px;
}

.error {
  color: var(--error-color);
}

.error::marker {
  color: var(--error-color);
}

.containerPhoneInput {
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 8px;
}

.containerPhoneInput ::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
  border: none;
}

.containerPhoneInput ::-webkit-scrollbar-track {
  margin-bottom: 22px;
  margin-top: 15px;
  border-radius: 16px;
  background-color: #f1f1f1;
  border: 1px solid rgba(141, 141, 141, 0.1);
}

.formLabel ::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px;
}

.formLabel ::-webkit-scrollbar-track {
  background: var(--secondary-gray-color);
}

.formLabel ::-webkit-scrollbar-thumb {
  background: var(--description-text-color);
  border-radius: 5px;
}

.formLabel ::-webkit-scrollbar-thumb:hover {
  background: var(--hover-button);
}

@media screen and (max-width: 500px) {
  .errorMessage,
  .emptyInputError {
    font-size: 14px;
  }
}
