.intendingSite {
  padding: 40px 0;
  background: #00002e;
}

.backgroundWrapper {
  display: none;
}

.textWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.title {
  font-size: 18px;
  width: 100%;
  margin-bottom: 0;
}

.text {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
}

.btn {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .intendingSite {
    padding: 0;
    padding-top: 60px;
    background: none;
  }

  .wrap {
    padding: 80px 30px;
    background: #00002e;
    border-radius: 40px;
  }

  .title {
    font-size: 36px;
  }

  .textWrap {
    font-weight: 500;
    color: var(--primary-color);
  }

  .text {
    font-size: 18px;
  }

  .btn {
    width: 221px;
    margin-right: auto;
  }
}

@media screen and (min-width: 1440px) {
  .intendingSite {
    padding-top: 120px;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0;
    border-radius: 40px;
  }

  .backgroundWrapper {
    display: block;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    align-self: center;
    width: 425px;
    height: 692px;
    background-image: url("../../../assets/intending-bg.webp");
  }

  .textWrap {
    width: 658px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text {
    margin-bottom: 40px;
  }
}
