.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.contentWrap {
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
}

.imgWrap {
  width: 100%;
  height: 509px;
  border-radius: 16px;
  overflow: hidden;
}

.stepsBlockWrap {
  width: 100%;
  display: flex;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;
  background: var(--primary-brand-color);
}

.title {
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 20px;
}

@media screen and (max-width: 374px) {
  .title {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .section {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .contentWrap {
    align-items: center;
    gap: 0;
  }

  .imgWrap {
    margin-bottom: 60px;
  }

  .stepsWrap {
    width: 660px;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .section {
    padding: 120px 0;
  }

  .stepsWrap {
    width: 660px;
  }

  .contentWrap {
    flex-direction: row;
    justify-content: space-between;
  }

  .imgWrap {
    height: 873px;
    width: 618px;
    margin: 0;
  }

  .title {
    display: block;
    margin-bottom: 40px;
    text-align: left;
  }
}
