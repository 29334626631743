.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--overlay-color);
  z-index: 1200;
  overflow: auto;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.closeImg * {
  fill: var(--white-color);
  stroke-width: 1;
  transition: var(--transition);
}

.closeBtn:hover .closeImg * {
  fill: var(--hover-button);
}

.stepModal {
  position: absolute;
  width: 100vw;
  max-height: 100vh;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  background: var(--primary-brand-color);
}

.stepModal::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
}

.modalTitle {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4;
  text-align: left;
}

.longText {
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.4;
}

.btnWrapper {
  width: 100%;
  margin-top: 20px;
}

.icon {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100px;
  width: 100px;
}

@media screen and (min-width: 375px) {
  .stepModal {
    width: 375px;
    max-height: 100vh;
    border-radius: 16px;
  }
}

@media screen and (min-width: 560px) {
  .stepModal {
    width: 540px;
    border: 3px solid var(--secondary-bg-color);
    padding: 40px;
  }

  .modalTitle {
    margin-bottom: 40px;
    font-size: 36px;
    text-align: left;
  }

  .longText {
    font-size: 18px;
    line-height: 25.2px;
  }

  .btnWrapper {
    margin-top: 40px;
  }
}

.blockWrap {
  padding: 14px;
  padding-left: 20px;
  border: 3px solid var(--primary-white-color);
  border-radius: 16px;
  width: 345px;
  width: 100%;
}

.firstBlock {
  cursor: pointer;
  transition: 0.5s;
  animation: stepBlock 1s infinite ease;
}

.firstBlock:hover {
  border-color: var(--accent-color);
  animation-play-state: paused;
}

@keyframes stepBlock {
  0% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}

.titleWrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.number {
  display: block;
  color: var(--accent-color);
  font-family: "Work Sans", sans-serif;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}

.title {
  font-size: 18px;
  line-height: 1.4;
}

.shortText {
  font-size: 16px;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .blockWrap {
    width: 320px;
    padding: 24px;
    justify-content: space-between;
  }

  .titleWrap {
    flex-direction: column;
    gap: 14px;
    margin-bottom: 8px;
  }
  .number {
    font-size: 48px;
    line-height: 56px;
  }

  .title {
    font-size: 20px;
  }

  .shortText {
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .blockWrap {
    padding: 24px;
  }
}
