.button {
  width: 100%;
  height: 51px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-family: var(--primary-font);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  transition: background-color var(--transition);
  text-transform: uppercase;
  letter-spacing: 0.02em;
  cursor: pointer;
  color: var(--primary-brand-color);
  background-color: var(--accent-color);
}

.button:hover,
.button:focus,
.button:active {
  background-color: var(--hover-button);
}
