/* teamate  */
.wrap {
  width: 126px;
  text-align: center;
  color: var(--white-color);
  padding: 10px 0;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-radius: 5px;
  transition: 0.3s;
}

.wrap:hover,
.wrap:active {
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 5px 5px 10px #000;
}

.img {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 15px;
}

.name,
.role,
.teamRole,
.teamCount {
  display: block;
}

.name {
  font-size: 18px;
  font-weight: 500;
}

.role {
  font-size: 12px;
  font-weight: 300;
}

/* team role count*/
.teamWrap {
  text-align: center;
  width: 50%;
  padding: 46px 0;
}

.teamIcon {
  width: 62px;
  height: 62px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.teamRole {
  font-size: 20px;
  font-weight: 500;
}

.teamCount {
  font-size: 15px;
  font-weight: 500;
  color: #afc6ff;
}

@media screen and (max-width: 1440px) {
  /* team role count*/

  .teamWrap {
    padding: 30px 0;
  }
  .teamIcon {
    width: 38px;
    height: 38px;
    margin-bottom: 5px;
  }
  .teamRole {
    font-size: 12px;
  }
  .teamCount {
    font-size: 10px;
  }
}
