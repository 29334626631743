.modal {
  color: var(--description-text-color);
  background-color: var(--white-color);
  border-radius: 40px;
  padding: 40px;
}

.title {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 36px;
  line-height: 1.4;
  text-align: center;
  color: var(--primary-brand-color);
}

.text {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}

.form {
  max-width: 420px;
  margin: 0 auto;
}

.inputList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.inputItem {
  position: relative;
}

.formInput {
  border: 1px solid var(--primary-gray-color);
  border-radius: 12px;
  padding: 8px 8px 16px;
  height: 48px;
  width: 100%;
  outline: none;
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 1px;
  color: var(--primary-brand-color);
  background-color: var(--primary-white-color);
  transition: border-color var(--transition), color var(--transition);
}

input[type="password"] {
  padding-right: 44px;
}

.formInput::placeholder {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: var(--primary-gray-color);
  letter-spacing: normal;
  padding-top: 16px;
}

.formInput:disabled {
  opacity: 0.5;
}

.formLabel {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: var(--primary-brand-color);
  height: 76px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.repeatPassErrorIcon {
  right: 50px;
}

.activeIcon {
  cursor: pointer;
}

.icon {
  transition: color var(--transition);
  fill: currentColor;
}

.navigateBtn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: var(--link-color);
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  transition: color var(--transition);
  text-decoration: underline;
}

.navigateBtn:hover,
.navigateBtn:focus {
  color: var(--hover-button);
}

.inputList ::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px;
}

.inputList ::-webkit-scrollbar-track {
  background: var(--secondary-gray-color);
}

.inputList ::-webkit-scrollbar-thumb {
  background: var(--description-text-color);
  border-radius: 5px;
}

.inputList ::-webkit-scrollbar-thumb:hover {
  background: var(--hover-button);
}

.errorMessage {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  position: absolute;
  top: 0;
  right: 0;
  color: var(--error-color);
}

.keyIcon {
  stroke: gray;
  transition: stroke var(--transition);
}

.formInput:hover ~ .keyIcon {
  stroke: var(--grey-light);
}

.form .newKey {
  margin-bottom: 0;
  margin-top: 5px;
}

.form .newKey:disabled {
  opacity: 0;
  cursor: auto;
}

.newPasswordText {
  color: var(--secondary-color);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  margin-bottom: 40px;
}

.closeIcon {
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: color var(--transition);
}

.closeIconR40 {
  right: 40px;
}

.closePolicyMobile {
  right: 35px;
}

.icon {
  fill: currentColor;
}

.backIcon {
  position: absolute;
  top: 40px;
  right: 12px;
}

/* == login modal / signup modal == */

.forgottenPassContentWrap .title,
.checkEmailContentWrap .title {
  margin-bottom: 12px;
}

.loginContentWrap .title,
.signupContentWrap .title,
.loginContentWrap .form,
.signupContentWrap .form {
  margin-bottom: 32px;
}

.forgottenPassContentWrap .newPassForm {
  margin: 0;
}

.forgottenPassContentWrap .navigateBtn {
  color: var(--primary-brand-color);
}

.checkEmailContentWrap .form {
  margin-bottom: 0;
}

.loginContentWrap .inputList {
  margin-bottom: 12px;
}

.signupContentWrap .inputList,
.forgottenPassContentWrap .inputList {
  margin-bottom: 32px;
}

.errorsList {
  list-style: inside;
}

.errorItem {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: var(--accepted-color);
}

.errorItem::marker {
  color: var(--accepted-color);
  font-size: 8px;
}

.error {
  color: var(--error-color);
}

.error::marker {
  color: var(--error-color);
}

.modalBottomInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: var(--primary-brand-color);
}

.checkboxWrapper {
  color: var(--secondary-brand-color);
  margin-bottom: 32px;
  font-size: 14px;
}

.specialNavigateLink {
  color: var(--link-color);
  padding: 0 4px;
}

/*== policy and terms modals styles == */

.policyContentWrap ::-webkit-scrollbar-track {
  background-color: var(--primary-gray-color);
}

.policyContentWrap ::-webkit-scrollbar-thumb {
  background-color: var(--primary-brand-color);
}

.policyContentWrap ::-webkit-scrollbar-thumb:hover {
  background-color: var(--hover-button);
}

.policyContentWrap {
  max-width: 420px;
  margin: 0 auto;
}

.policyContentWrap .title {
  text-align: center;
  margin-bottom: 32px;
}

.content {
  height: 70vh;
  overflow: auto;
  margin-bottom: 32px;
  padding: 0 12px;
  color: var(--primary-brand-color);
}

/* == success modal == */
.successModalWrap .text,
.successModalWrap .title {
  margin-bottom: 12px;
}

.successModalWrap .navigateBtn {
  padding-top: 8px;
}

/* == new password modal == */

.keyIcon {
  stroke: gray;
  transition: stroke var(--transition);
}

.formInput:hover ~ .keyIcon {
  stroke: var(--grey-light);
}

.form .newKey {
  font: var(--primary-font);
  margin-bottom: 0;
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.2;
  color: var(--link-color);
  text-decoration: underline;
}

.form .newKey:hover,
.form .newKey:focus {
  color: var(--hover-button);
}

.forggotenPassText {
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 32px;
}

/* == remove memory page modal == */

.checkEmailContentWrap .titleRemoveMemoryPage {
  margin-bottom: 60px;
}
/* == delete profile modal == */

.deleteProfileInner .title {
  margin-bottom: 12px;
}

.deleteProfileInner .text {
  text-align: center;
  margin-bottom: 32px;
}

/* == mobile == */

@media screen and (max-width: 530px) {
  .modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    padding: 15px;
    min-height: 100vh;
  }

  .content {
    height: 50vh;
  }
}

@media screen and (max-width: 450px) {
  .form {
    width: calc((100vw - 30px));
  }

  .errorMessage,
  .emptyInputError {
    font-size: 14px;
  }

  /* == LogIn == */

  .loginContentWrap .navigateBtn,
  .forgottenPassContentWrap .navigateBtn {
    padding: 10px;
    padding-left: 0;
  }

  .loginContentWrap .modalBottomInfo {
    margin-bottom: 0;
  }

  .loginNavigateBtn {
    margin-bottom: 24px;
  }

  /* == Policy == */

  .modal ::-webkit-scrollbar {
    width: 16px;
    display: none;
  }

  /* == Success == */

  .forgottenPassContentWrap .forggotenPassText,
  .checkEmailContentWrap .forggotenPassText {
    width: 300px;
  }

  .checkEmailContentWrap {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 390px) {
  .policyContentWrap .title {
    font-size: 24px;
  }
}

@media screen and (max-width: 350px) {
  .signupContentWrap .inputList {
    margin-bottom: 20px;
  }

  .loginContentWrap .modalBottomInfo {
    flex-direction: column;
    align-items: start;
    gap: 0;
  }

  .checkboxWrapper p,
  .checkboxWrapper p .navigateBtn,
  .loginContentWrap .modalBottomInfo p,
  .loginContentWrap .modalBottomInfo .navigateBtn {
    font-size: 14px;
  }

  .checkboxWrapper {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .checkboxWrapper p {
    display: flex;
    flex-direction: column;
  }

  .checkboxWrapper p .navigateBtn {
    padding: 5px;
    padding-left: 0;
  }

  .text,
  .forgottenPassContentWrap .forggotenPassText,
  .checkEmailContentWrap .forggotenPassText {
    width: 100%;
  }
}

@media screen and (max-width: 374px) {
  .signupContentWrap,
  .loginContentWrap {
    padding-bottom: 80px;
  }

  .formInput {
    padding: 10px 8px 20px;
  }

  .formInput::placeholder {
    font-size: 12px;
  }

  .errorMessage {
    font-size: 12px;
  }

  .formLabel {
    font-size: 12px;
  }

  .modalBottomInfo {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .closeIcon:hover,
  .closeIcon:focus {
    color: var(--hover-button);
  }
}
